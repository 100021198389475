import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, ButtonGroup, Grid, IconButton } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import AddIcon from "@mui/icons-material/Add";
import ModalImage from "react-modal-image-responsive";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CartContext from "../../Context/Cart/CartContext";
import { useContext } from "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";

const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "2px solid #9EC8B9  ",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    borderRadius: "5px",
  },
});

export default function CardProduct({ product }) {
  const classes = useStyles();
  const { img } = product;
  const miImagen = require("../../assets/img/default.webp");

  const {
    AddProductCart,
    ReduceQuantityProduct,
    ModifyQuantityProduct,
    IncreaseQuantityProduct,
    DeleteProductCart,
  } = useContext(CartContext);
  const [quantity, setQuantity] = useState(product.quantity);
  const handleChangeQuantity = async (e, id) => {
    const data = {};
    if (e.target.value === "" || !isNaN(e.target.value)) {
      setQuantity(e.target.value);
    }
    data.product_id = id;
    data.quantity = Number(e.target.value);

    await ModifyQuantityProduct(data);
  };
  return (
    <div className="plan">
      <div className="inner">
        <span class="pricing">
          <span>
            $ {product.price_purchase} <small>/ mxn</small>
          </span>
        </span>
        <p class="title">{product.name}</p>
        <div>
          {img?.length > 0 ? (
            <Box sx={{ width: "100%", display: "flex" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{ width: "100%", height: "50%", position: "static" }}
                  >
                    <ModalImage
                      className={classes.imgproduct}
                      small={img[0]?.image}
                      large={img[0]?.image}
                      alt={product.name}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box sx={{ width: "100%", display: "flex" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{ width: "100%", height: "50%", position: "relative" }}
                  >
                    <ModalImage
                      className={classes.imgproduct}
                      small={miImagen}
                      large={miImagen}
                      alt={product.name}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            {product.quantity === 1 ? (
              <Button
                onClick={() => DeleteProductCart(product.id)}
                sx={{ marginTop: -2 }}
              >
                <HorizontalRuleIcon
                  sx={{ color: "#092635", fontSize: "25px" }}
                />
              </Button>
            ) : (
              <Button
                onClick={() => ReduceQuantityProduct(product.id)}
                sx={{ marginTop: -2 }}
              >
                <HorizontalRuleIcon
                  sx={{ color: "#5C8374", fontSize: "25px" }}
                />
              </Button>
            )}
            {product.quantity === 0 ? (
              <input
                className={classes.jss59}
                placeholder="Agrega la Cantidad Inicial"
                name="quantityProduct"
                min={0}
                disabled
                max={product.stock}
                value={product.quantity}
                onChange={(e) => handleChangeQuantity(e, product.id)}
              />
            ) : (
              <input
                className={classes.jss59}
                placeholder="Agrega la Cantidad Inicial"
                name="quantityProduct"
                min={1}
                max={product.stock}
                value={product.quantity}
                onChange={(e) => handleChangeQuantity(e, product.id)}
              />
            )}
            {product.quantity === product.stock ? (
              <Button
                disabled
                sx={{ color: "#1B4242", marginTop: -2 }}
                onClick={() => AddProductCart(product.id)}
              >
                <AddIcon sx={{ color: "#1B4242", fontSize: "25px" }} />
              </Button>
            ) : (
              <Button
                sx={{ color: "#1B4242", marginTop: -2 }}
                onClick={() => AddProductCart(product.id)}
              >
                <AddIcon sx={{ color: "#1B4242", fontSize: "25px" }} />
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
