import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AuthContext from "../../Context/auth/AuthContext";
import InventoryIcon from "@mui/icons-material/Inventory";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import HelpIcon from "@mui/icons-material/Help";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CartContext from "../../Context/Cart/CartContext";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  Badge,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Header({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const AA = [
    {
      name: "Inicio",
      value: "/inicio-admin",
      icon: (
        <Tooltip title="Inicio" placement="right">
          <HomeIcon />
        </Tooltip>
      ),
    },
    {
      name: "Categorias",
      value: "/categorias-admin",
      icon: (
        <Tooltip title="Categorias" placement="right">
          <CategoryIcon />
        </Tooltip>
      ),
    },
    {
      name: "Productos",
      value: "/productos-admin",
      icon: (
        <Tooltip title="productos" placement="right">
          <WarehouseIcon />
        </Tooltip>
      ),
    },
    {
      name: "Ventas",
      value: "/ventas-admin",
      icon: (
        <Tooltip title="ventas" placement="right">
          <MonetizationOnIcon />
        </Tooltip>
      ),
    },
    {
      name: "Ayuda",
      value: "/ayuda-admin",
      icon: (
        <Tooltip title="Ayuda" placement="right">
          <HelpIcon />
        </Tooltip>
      ),
    },
  ];

  const CLIENTE = [
    {
      name: "Inicio",
      value: "/",
      icon: (
        <Tooltip title="Inicio" placement="right">
          <HomeIcon />
        </Tooltip>
      ),
    },
    {
      name: "Categorias",
      value: "/Categorias",
      icon: (
        <Tooltip title="Categorias" placement="right">
          <CategoryIcon />
        </Tooltip>
      ),
    },
    // {
    //   name: "Mis direcciones",
    //   value: "/direcciones",
    //   icon: (
    //     <Tooltip title="Direcciones" placement="right">
    //       <WhereToVoteIcon />
    //     </Tooltip>
    //   ),
    // },
    {
      name: "Mi Pedidos",
      value: "/pedidos",
      icon: (
        <Tooltip title="Mis pedidos" placement="right">
          <InventoryIcon />
        </Tooltip>
      ),
    },
    {
      name: "Ayuda",
      value: "/ayuda",
      icon: (
        <Tooltip title="Ayuda" placement="right">
          <HelpIcon />
        </Tooltip>
      ),
    },
  ];
  const { cerrarSesion, usuario } = useContext(AuthContext);
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#092635" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src={require("../../assets/img/logotipo.png")} height={50} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title="Carrito de compras">
              <Link to={"/carrito"}>
                <Badge
                  badgeContent={cart ? cart.length : 0}
                  color="primary"
                  sx={{ mt: 1, mr: 2 }}
                >
                  <ShoppingCartIcon sx={{ color: "white", fontSize: 30 }} />
                </Badge>
              </Link>
            </Tooltip>
            <Tooltip title="Cerrar Sesión">
              <IconButton sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ExitToAppIcon
                  sx={{ color: "white", fontSize: 30 }}
                  onClick={() => cerrarSesion()}
                >
                  cerrarSesion
                </ExitToAppIcon>
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "#092635" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {usuario && usuario.type_user === 0
            ? AA.map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <Link to={text.value} style={{ textDecoration: "none" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "#451952",
                        }}
                      >
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={text.name}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "#662549",
                          fontWeight: "bold",
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))
            : usuario &&
              usuario.type_user === 1 &&
              CLIENTE.map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <Link to={text.value} style={{ textDecoration: "none" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "#9EC8B9",
                        }}
                      >
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={text.name}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "#092635",
                          fontWeight: "bold",
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
        </List>
      </Drawer>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DrawerHeader />
          {children}
        </Grid>
      </Grid>
      {/* </Box> */}
    </Box>
  );
}
