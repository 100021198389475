import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../Context/Cart/CartContext";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import MethodGet from "../../config/Service";
import SelectDirection from "../../containers/SelectOptions/DirectionSelect";
import AddDirection from "../../containers/Directions/AddDirection";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "30%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const TableProductsCart = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    GetCartUserLogged,
    cart,
    ModifyQuantityProduct,
    GetTotalCart,
    totalCart,
    DeleteProductCart,
  } = useContext(CartContext);
  const [spinnerCargando, setSpinnerCargando] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [id_business, setIdBusiness] = useState(null);
  const [total, setTotal] = useState(0);
  const [direction, setDirection] = useState(null);
  const detectarCambiosDirection = (e) => {
    setDirection(e.target.value);
  };

  useEffect(() => {
    GetCartUserLogged();
  }, []);

  const handleChangeQuantity = async (e, id) => {
    const data = {};
    data.product_id = id;
    data.quantity = Number(e.target.value);

    await ModifyQuantityProduct(data);
  };

  const handleClickDelete = async (id) => {
    setSpinnerCargando(true);
    await DeleteProductCart(id);
  };

  const subtotal = cart.map((c) => c.quantity * c.price);
  var sumaSubtotal = subtotal.reduce(function (acc, numero) {
    return acc + numero;
  }, 0);

  useEffect(() => {
    let url = `/Discount/${sumaSubtotal}`;
    MethodGet(url)
      .then((res) => {
        setDiscount(res.data.discount);
        setIdBusiness(res.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sumaSubtotal]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const calculateTotal = (sumaSubtotal, discount) => {
    setTotal(sumaSubtotal * (1 - discount / 100));
  };
  useEffect(() => {
    calculateTotal(sumaSubtotal, discount);
  }, [sumaSubtotal, cart, discount]);
  const { CreateOrder } = useContext(OrdersContext);
  const onSubmit = (data) => {
    data.status = 0;
    data.subtotal = sumaSubtotal;
    data.id_business_rule = id_business;
    data.total = total;
    // data.direction_id = direction;
    // console.log(data);
    CreateOrder(data);
    history.push("/pedidos");
  };

  const [openAddDirection, setOpenAddDirection] = useState(false);
  const handleClickOpenAddDirection = () => {
    setOpenAddDirection(true);
  };
  const handleCloseAddDirection = () => {
    setOpenAddDirection(false);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <div
          style={{
            width: "100%",
            minWidth: "380px",
            minHeight: 100,
            maxHeight: 550,
            overflowY: "auto",
            padding: 5,
          }}
        >
          {cart.map((product) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              key={product.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { sx: "column", md: "row" },
                boxShadow: 5,
                padding: 3,
                marginBottom: 2,
                borderRadius: 5,
                backgroundColor: "#9EC8B9",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Typography>{product.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                  <input
                    //type="number"
                    //onChange={detectarCambiosCantidadComprada}
                    className={classes.jss59}
                    placeholder="Agrega la Cantidad Inicial"
                    name="quantityProduct"
                    min={1}
                    max={product.stock}
                    value={product.quantity}
                    onChange={(e) => handleChangeQuantity(e, product.id)}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
                  <Typography> $ {product.price}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
                  {product.stock === 0 ? (
                    <p style={{ color: "black", fontWeight: "bold" }}>
                      Sin existencia
                    </p>
                  ) : product.stock > 5 && product.stock <= 24 ? (
                    <p style={{ color: "#000", fontWeight: "bold" }}>
                      {product.stock} pzas disponibles
                    </p>
                  ) : (
                    product.stock > 25 && (
                      <p style={{ color: "#000", fontWeight: "bold" }}>
                        {product.stock} pzas disponibles!
                      </p>
                    )
                  )}
                  <Typography> </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
                  <Typography>$ {product.quantity * product.price}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={1}>
                  <IconButton onClick={() => handleClickDelete(product.id)}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </div>

        {cart.length > 0 && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 1,
              }}
            >
              <Typography fontWeight="bold">Subtotal:</Typography>
              <Typography>$ {sumaSubtotal.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 1,
              }}
            >
              <Typography fontWeight="bold">Descuento:</Typography>
              <Typography>{discount ? discount : 0} %</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 1,
              }}
            >
              <Typography fontWeight="bold">Total:</Typography>
              <Typography>$ {total.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter")
                    e.preventDefault();
                }}
                autoComplete="off"
              >
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  fullWidth
                  sx={{
                    backgroundColor: "#1B4242",
                    color: "white",
                    "&:hover": { backgroundColor: "#1B4242", color: "white" },
                  }}
                >
                  Terminar compra
                </Button>
              </form>
            </Grid>
          </Grid>
        )}
        <AddDirection
          open={openAddDirection}
          handleClose={handleCloseAddDirection}
        />
      </Grid>
    </>
  );
};

export default TableProductsCart;
