import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import MethodGet from "../../config/Service";
export default function UpdateDirection({ open, handleClose, id }) {
  const { updateDirection } = useContext(DirectionsContext);
  const [direction, setDirection] = useState(null);
  useEffect(() => {
    let url = `/Directions/${id}`;
    MethodGet(url)
      .then((res) => {
        setDirection(res.data.direction);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const direction = formJson.direction;
            const data = {};
            data.direction = direction;
            data.id = id;
            updateDirection(data);
            handleClose();
          },
        }}
      >
        <DialogTitle>Editar dirección</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Actualiza la dirección en el siguiente cuadro de texto
          </DialogContentText>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            required
            id="outlined-textarea"
            label="Direccion"
            name="direction"
            placeholder="Ingresa tu direccion aquí"
            multiline
            defaultValue={direction}
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="contained">
            Cancelar
          </Button>
          <Button type="submit" color="success" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
