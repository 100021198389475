import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import PedidosContext from "../../Context/Pedidos/PedidosContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalTicketPayment from "../../components/Cart/ModalTicketPayment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Orders = () => {
  const { GetAllOrdersPendingUser, orders_client } = useContext(PedidosContext);
  useEffect(() => {
    GetAllOrdersPendingUser();
  }, []);
  const [open, setOpen] = React.useState(false);
  const [order_id, setOrderId] = useState(null);
  const [total, setTotal] = useState(null);
  const handleClickOpen = (id, total) => {
    setOrderId(id);
    setTotal(total);
    setOpen(true);
  };

  const handleClose = () => {
    setOrderId(null);
    setTotal(null);
    setOpen(false);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" textAlign="center" fontSize="35px">
            Mis pedidos
          </Typography>
        </Grid>
        {orders_client &&
          orders_client.map((o, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <Card boxShadow={16}>
                <CardHeader
                  title={
                    o.status === 0 ? (
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "#d50000" }}
                      >
                        Pendiente de comprobante de pago
                      </Typography>
                    ) : o.status === 1 ? (
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "#ff8f00" }}
                      >
                        Validando pago
                      </Typography>
                    ) : o.status === 2 ? (
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "#ff8f00" }}
                      >
                        En preparación
                      </Typography>
                    ) : (
                      o.status === 3 && (
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ color: "#4caf50" }}
                        >
                          Enviado
                        </Typography>
                      )
                    )
                  }
                />
                <CardContent>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Folio: {o.id}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Cliente: {o.name}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Telefono: {o.number_phone}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total: $ {o.total}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total pagado: ${" "}
                    {o.payments
                      ? o.payments
                          .map((payment) => payment.amount)
                          .reduce((total, amount) => total + amount, 0)
                      : 0}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 2,
                    }}
                  >
                    <Grid>
                      {o.status === 0 &&
                        o.payments
                          .map((payment) => payment.amount)
                          .reduce((total, amount) => total + amount, 0) <
                          o.total && (
                          <Tooltip title="Subir comprobante" placement="top">
                            <IconButton
                              onClick={() => handleClickOpen(o.id, o.total)}
                            >
                              <UploadFileIcon sx={{ color: "#4caf50" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      <Tooltip title="Detalle de orden" placement="top">
                        <Link to={`/Detalle-venta/${o.id}`}>
                          <IconButton>
                            <VisibilityIcon sx={{ color: "#3f51b5" }} />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      {order_id !== null && total !== null && (
        <ModalTicketPayment
          open={open}
          handleClose={handleClose}
          order_id={order_id}
          total={total}
        />
      )}
    </Layout>
  );
};

export default Orders;
