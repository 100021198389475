import React from "react";
import Header from "./Header";
import "./styles.css";

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Header children={children} />
      </div>
    </>
  );
};

export default Layout;
