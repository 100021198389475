import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Box } from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Image } from "react-bootstrap";
export default function DetalleProduct({ product, open, handleClose }) {
  console.log(product);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Detalle de producto"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Codigo de barras: {product.tag}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Nombre: {product.name}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Descripción: {product.description}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Precio: $ {product.price_purchase}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Stock: <b> {product.stock} </b> pzas disponibles
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Categoría: {product.category.name}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              Subcategoría: {product.subcategory.name}
            </Grid> */}
            {product === null ? (
              <>Cargando Imagenes</>
            ) : (
              product.img.length && (
                <Box
                  sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ width: "100%", margin: "0 auto" }}>
                        <Slide>
                          {product.img.map((fadeImage, index) => (
                            <div className="each-fade" key={index}>
                              <div className="image-container">
                                <Image
                                  src={fadeImage.image}
                                  style={{ width: "100%", height: "50%" }}
                                />
                              </div>
                              <h2>{fadeImage.caption}</h2>
                            </div>
                          ))}
                        </Slide>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            sx={{
              backgroundColor: "red",
              color: "white",
              "&:hover": { backgroundColor: "red", color: "white" },
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
