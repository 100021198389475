import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { makeStyles, styled } from "@mui/styles";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import image from "../../assets/img/seller.svg";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthContext from "../../Context/auth/AuthContext";
import { useFormik } from "formik";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
const useStyles = makeStyles({
  textlogin: {
    fontSize: "15px",
    color: "black",
    fontWeight: "bold",
    fontStyle: "oblique",
    letterSpacing: "1px",
  },
  backgroundLogin: {
    height: "100vh",
    width: "100%",
    // backgroundImage: "url(https://source.unsplash.com/random/2560x1440)",
    backgroundColor: "#092635",
    backgroundRepeat: "no-repeat",
    opacity: 1,
    overflowY: "none",
    overflowX: "none",
  },
  caja: {
    background: "#5C8374",
    borderRadius: "0px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(9.2px)",
    border: "1px solid rgba(53, 70, 93, 0.3)",
  },
  cajaInfo: {
    background: "#1B4242",
    borderRadius: "0px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(14.4px)",
    border: "1px solid rgba(106, 214, 195, 0.3))",
  },
});

const Register = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { registerUser } = useContext(AuthContext);
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      registerUser(formData);
    },
  });

  const benefits = [
    { name: "Descuentos Exclusivos" },
    { name: "Promociones" },
    { name: "Publicidad" },
    { name: "Excelentes Ganancias" },
    { name: "Precio de distribuidor" },
  ];

  return (
    <div className={classes.backgroundLogin}>
      <Grid container spacing={0} justifyContent="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: -10,
            marginTop: 5,
          }}
        >
          <img
            src={require("../../assets/img/logotipo.png")}
            width={300}
            height={150}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box
            sx={{
              boxShadow: 3,
              mr: 0,
              ml: 5,
              padding: 4,
              position: "relative",
              marginTop: 20,
            }}
            className={classes.cajaInfo}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  marginBottom: "10px",
                  fontSize: "40px",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                ¿Porque ser Distribuidor?
              </div>
            </Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box>
                    <img src={image} width="100%" height="100%" />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "transparent",
                    }}
                    aria-label="contacts"
                  >
                    {benefits.map((bene, index) => (
                      <ListItem disablePadding key={index}>
                        <ListItemButton>
                          <ListItemIcon>
                            <StarIcon sx={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={bene.name}
                            sx={{ color: "white" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box
            sx={{
              boxShadow: 3,
              mr: 5,
              ml: 0,
              padding: 4,
              position: "relative",
              marginTop: 20,
            }}
            className={classes.caja}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  marginBottom: "10px",
                  fontSize: "40px",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                Registro
              </div>
            </Box>

            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Nombre completo:"
                    name="name"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    error={formik.errors?.name ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    id="number_phone"
                    label="Numero Telefonico:"
                    name="number_phone"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    error={formik.errors?.number_phone ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <TextField
                    id="direction"
                    label="Direccion"
                    fullWidth
                    multiline
                    rows={2}
                    autoComplete="off"
                    placeholder="Ingresa tu direccion completa aqui"
                    onChange={formik.handleChange}
                    error={formik.errors?.direction ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      htmlFor="password"
                      error={formik.errors?.password ? true : false}
                    >
                      Contraseña:
                    </InputLabel>
                    <OutlinedInput
                      error={formik.errors?.password ? true : false}
                      id="password"
                      name="password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(event) => {
                        setValues({ ...values, password: event.target.value });
                        formik.handleChange(event);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff sx={{ color: "#313866" }} />
                            ) : (
                              <Visibility sx={{ color: "#313866" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Contraseña:"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "#092635",
                  color: "white",
                  fontWeight: "bold",
                  mt: 3,
                  mb: 2,
                  "&:hover": {
                    backgroundColor: "#092635",
                  },
                }}
              >
                <Typography
                  fontFamily="monospace"
                  fontWeight="bold"
                  variant="subtitle1"
                >
                  Registrarme
                </Typography>
              </Button>
              <Typography
                fontFamily="monospace"
                fontWeight="bold"
                variant="subtitle2"
                textAlign="center"
                sx={{ color: "white" }}
              >
                ¿Ya tienes cuenta?
              </Typography>
              <br />
              <Link to="/iniciar-sesion">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#092635",
                    color: "white",
                    fontWeight: "bold",
                    mt: 0,
                    mb: 6,
                    "&:hover": {
                      backgroundColor: "#092635",
                    },
                  }}
                >
                  <Typography
                    fontFamily="monospace"
                    fontWeight="bold"
                    variant="subtitle1"
                  >
                    Iniciar sesión
                  </Typography>
                </Button>
              </Link>
              <br />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;
function initialValues() {
  return {
    number_phone: "",
    password: "",
    name: "",
    direction: "",
  };
}

function validationSchema() {
  return {
    name: Yup.string().min(1).required("El nombre completo es requerido"),
    number_phone: Yup.number().min(10).required("Telefono requerido"),
    password: Yup.string().min(8).required("Contraseña requerida"),
    direction: Yup.string().min(8).required("La direccion es requerida"),
  };
}
