import React from "react";
import OrdersReducer from "./OrdersReducer";
import OrdersContext from "./OrdersContext";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  APROVE_ORDER,
  GET_ALL_ORDER,
  GET_ALL_ORDERS_APROVE,
  GET_ALL_ORDERS_COMPLETED,
  GET_ALL_ORDERS_PENDING,
  GET_ALL_ORDERS_PREPARING,
  GET_ALL_ORDERS_SHIPPING,
  MAKE_AS_READY,
  TAKE_ORDER,
} from "../../types";
import { useReducer } from "react";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import clienteAxios from "../../config/Axios";
const OrdersState = ({ children }) => {
  const initialState = {
    orders: [],
    ordersCompleted: [],
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(OrdersReducer, initialState);
  const GetAllOrders = () => {
    let url = `/Sales`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDER,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetAllOrdersCompleted = () => {
    let url = `/finalizedsales`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_COMPLETED,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const PdfOrder = (order) => {
    Swal.fire({
      title: "¿Descargar PDF de la orden?",
      text: "Se descargará el pdf de la orden",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El PDF se esta generando!",

          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        let url = `/ticket/${order.id}`;
        clienteAxios
          .get(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, `Orden_${order.id}.pdf`);
            // const safeFileName = encodeURIComponent(`Orden_${order.id}.pdf`);
            // fileDownload(res.data, safeFileName);
            Swal.fire({
              title: "Descargado",
              text: "El PDF de la orden se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el PDF",
            });
          });
      }
    });
  };

  const SalesOfDay = () => {
    Swal.fire({
      title: "¿Descargar Corte de caja?",
      text: "Se descargará el corte de caja",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/cashreport/`;
        clienteAxios
          .get(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, `corte_de_caja.pdf`);
              const safeFileName = encodeURIComponent(`corte_de_caja.pdf`);
              fileDownload(res.data, safeFileName);
              Swal.fire({
                title: "Descargado",
                text: "El PDF del corte de caja se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el PDF",
            });
          });
      }
    });
  };

  const CreateOrder = (data) => {
    // console.log(data, "la data en el state");
    let url = `/Sales`;
    Swal.fire({
      title: "Creando orden...!",
      showConfirmButton: false,
      showLoading: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    MethodPost(url, data)
      .then((res) => {
        Swal.fire({
          title: "Completada",
          text: "Tu compra se ha realizado con exito, ahora toca subir el comprobante de tu pago!",
          icon: "success",
          //timer: 5000,
          showConfirmButton: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MakeAsReady = (data) => {
    let url = `/Sms/${data.id}`;
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: MAKE_AS_READY,
          payload: data.id,
        });
        let timerInterval;
        Swal.fire({
          title: "Enviando",
          html: "Marcando el pedido como enviado!",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          // fileDownload(res.data, `Orden_${order.id}.pdf`);
          // const safeFileName = encodeURIComponent(`Orden_${order.id}.pdf`);
          // fileDownload(res.data, safeFileName);
          Swal.fire({
            title: "Notificado",
            text: "Se ha enviado un sms al cliente para notificarle que su pedido, ya sido enviado",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Se ha producido un error al enviar el SMS",
        });
      });
  };
  const GetAllOrdersPending = () => {
    let url = "/SalesPendindVerification";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_PENDING,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetAllOrdersAprove = () => {
    let url = "/SalesApprove";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_APROVE,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetAllordersPreparing = () => {
    let url = "/SalesPreparing";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_PREPARING,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetAllOrdersShipping = () => {
    let url = "/SalesShipp";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_SHIPPING,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AproveOrder = (id) => {
    Swal.fire({
      title: "Pago Verificado",
      text: "Se marcara como pago verificado",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, continuar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/aproveOrder/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: APROVE_ORDER,
              payload: id,
            });
          })
          .catch((error) => console.log(error));
      }
    });
  };
  const TakeOrder = (id) => {
    Swal.fire({
      title: "Preparar pedido",
      text: "Se marcara como preparando pedido",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, continuar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/preparingOrder/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: TAKE_ORDER,
              payload: id,
            });
          })
          .catch((error) => console.log(error));
      }
    });
  };

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        ordersCompleted: state.ordersCompleted,
        GetAllOrders,
        PdfOrder,
        GetAllordersPreparing,
        GetAllOrdersShipping,
        CreateOrder,
        MakeAsReady,
        SalesOfDay,
        GetAllOrdersCompleted,
        GetAllOrdersPending,
        GetAllOrdersAprove,
        AproveOrder,
        TakeOrder,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersState;
