import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
import { useContext } from "react";
export default function AddDirection({ open, modal, handleClose }) {
  const { storeDirection } = useContext(DirectionsContext);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const direction = formJson.direction;
            const data = {};
            data.direction = direction;
            storeDirection(data);
            handleClose();
          },
        }}
      >
        <DialogTitle>Agregar nueva dirección</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Registra una nueva dirección en el siguiente cuadro de texto
          </DialogContentText>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="outlined-textarea"
            label="Direccion"
            name="direction"
            placeholder="Ingresa tu direccion aquí"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="contained">
            Cancelar
          </Button>
          <Button type="submit" color="success" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
