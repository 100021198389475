import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import Header from "../../components/Header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MethodGet from "../../config/Service";
import { useState } from "react";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import ModalImage from "react-modal-image-responsive";
import Layout from "../../components/Layout/Layout";
import SpinnerComponentSanhoo from "../../components/loading/SpinnerComponentSanhoo";
const ShowOrder = (props) => {
  const { id } = props.match.params;
  const [order, saveOrder] = useState([]);
  const [payments, setPayments] = useState(null);
  const [client, setClient] = useState(null);
  const [products, setProducts] = useState(null);
  const [discount, setDiscount] = useState(null);
  useEffect(() => {
    let url = `/Sales/${id}`;
    MethodGet(url)
      .then((res) => {
        saveOrder(res.data.venta);
        setPayments(res.data.venta.payments);
        setClient(res.data.venta.client);
        setProducts(res.data.venta.details);
        setDiscount(res.data.venta.business);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {/* <Header /> */}
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Typography fontSize="35px">Detalle de venta </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <IconButton
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": { backgroundColor: "black", color: "white" },
            }}
            onClick={() => props.history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {order ? (
            <Card boxShadow={10} sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  Folio:{order.id}{" "}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  Cliente: {client ? client.name : ""}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  Subtotal:$ {order.subtotal}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  Descuento: {discount ? discount.discount : 0} %
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  Total:$ {order.total}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Dirección de envío: {order.direction?.direction}
                </Grid> */}
                <Divider />
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>C.Barras</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">cantidad</TableCell>
                          <TableCell align="center">P.Unitario</TableCell>
                          <TableCell align="center">subtotal</TableCell>
                          <TableCell align="center">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products &&
                          products.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.product.tag}
                              </TableCell>
                              <TableCell align="center">
                                {row.product.name}
                              </TableCell>
                              <TableCell align="center">
                                {row.quantity}
                              </TableCell>
                              <TableCell align="center">
                                $ {row.product.price_purchase}
                              </TableCell>
                              <TableCell align="center">
                                $ {row.product.price_purchase * row.quantity}
                              </TableCell>
                              <TableCell align="center">
                                $ {row.final_price * row.quantity}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          ) : (
            <SpinnerComponentSanhoo />
          )}
        </Grid>
        {payments !== null && payments.length > 0 && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" fontWeight="bold">
                Detalle de pago
              </Typography>
            </Grid>
            {payments.map((payment) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Monto Pagado: $ {payment.amount}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Referencia: {payment.reference}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Comprobante:
                    </Typography>
                    <ModalImage
                      small={payment.image}
                      large={payment.image}
                      alt={payment.reference}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Layout>
  );
};

export default ShowOrder;
