import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { Fab, Grid, TextField, Typography } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useForm } from "react-hook-form";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
import MethodGet from "../../../config/Service";
import SelectCategory from "../../SelectOptions/CategorySelect";
import SubcategorySelect from "../../SelectOptions/SubcategorySelect";
import ProductsContext from "../../../Context/Products/ProductsContext";
import SpinnerComponentSanhoo from "../../../components/loading/SpinnerComponentSanhoo";
const EditarProduct = (props) => {
  const { id } = props.match.params;
  const [product, saveProduct] = useState(null);
  const [cargando, spinnerCargando] = useState(false);
  const [category, saveCategory] = useState(null);
  const [subcategory, saveSubcategory] = useState(null);
  const { UpdateProduct } = useContext(ProductsContext);
  useEffect(() => {
    let url = `/Products/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProduct(res.data);
        saveCategory(res.data.id_categorie);
        saveSubcategory(res.data.id_subcategorie);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
        console.log(error);
      });
  }, [id]);

  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };
  const detectarCambiosSubcategory = (e) => {
    saveSubcategory(e.target.value);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  console.log(product);
  const onSubmit = (data, e) => {
    // if (pruebaTags.length) {
    //   data.tags = pruebaTags;
    // } else {
    //   data.tags = tagsSelected;
    // }

    data.id = id;
    // data.brand = brandId;
    // //data.location = location;
    // data.product_type = typeProductId;
    data.id_categorie = category;
    data.id_subcategorie = subcategory;
    // data.price = Number(data.price);

    // if (typeProductId === "2") {
    //   data.variation = variantId;
    // }
    // data.price_purchase = PriceFormat(data.price_purchase);

    // console.log(data, "la data que se actualiza");
    UpdateProduct(data);
    props.history.goBack();
  };
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography textAlign="center" fontWeight="bold" fontSize="40px">
            Editar Producto
          </Typography>
          {product ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  e.preventDefault();
              }}
              autoComplete="off"
            >
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "justify" }}
              >
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="tag"
                    label="Codigo de barras"
                    name="tag"
                    defaultValue={product.tag}
                    error={errors.tag ? true : false}
                    helperText={errors?.tag?.message}
                    {...register("tag", {
                      required: {
                        value: true,
                        message: "EL codigo de barras es necesario",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <TextField
                    variant="outlined"
                    id="name"
                    label="Nombre"
                    name="name"
                    fullWidth
                    defaultValue={product.name}
                    error={errors.name ? true : false}
                    helperText={errors?.name?.message}
                    {...register("name", {
                      required: {
                        value: true,
                        message: "El Nombre es requerido",
                      },
                      minLength: {
                        value: 1,
                        message: "Minimo 1 caracteres",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximo 255 caracteres",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <TextField
                    fullWidth
                    id="price"
                    name="price_purchase"
                    label="Precio"
                    defaultValue={product.price_purchase}
                    variant="outlined"
                    error={errors.price_purchase ? true : false}
                    helperText={errors?.price_purchase?.message}
                    {...register("price_purchase", {
                      required: {
                        value: true,
                        message: "El precio es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    id="stock"
                    name="stock"
                    label="Stock"
                    defaultValue={product.stock}
                    variant="outlined"
                    error={errors.stock ? true : false}
                    helperText={errors?.stock?.message}
                    {...register("stock", {
                      required: {
                        value: true,
                        message: "El Stock es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Descripción"
                    defaultValue={product.description}
                    variant="outlined"
                    error={errors.description ? true : false}
                    helperText={errors?.description?.message}
                    {...register("description", {
                      required: {
                        value: true,
                        message: "La descripción es requerida",
                      },
                    })}
                  />
                </Grid>
                {category !== null && (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <SelectCategory
                      detectarCambiosCategory={detectarCambiosCategory}
                      categoryId={product.id_categorie}
                    />
                  </Grid>
                )}
                {subcategory !== null && (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <SubcategorySelect
                      detectarCambiosSubcategory={detectarCambiosSubcategory}
                      subcategoryId={product.id_subcategorie}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div css={{ position: "relative", marginTop: 2 }}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}
                    >
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Grid>
              </Grid>
            </form>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerComponentSanhoo />
            </div>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EditarProduct;
