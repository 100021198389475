import { Button, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ProductsContext from "../../Context/Products/ProductsContext";
import ProductCartNoAuth from "../../components/Products/ProductCartNoAuth";
import Swal from "sweetalert2";

const Catalogo = () => {
  const { GetAllProductsNoAuth, products } = useContext(ProductsContext);
  useEffect(() => {
    GetAllProductsNoAuth();
  }, []);
  const LOGIN_URL = "/iniciar-sesion";
  const REGISTRATION_URL = "/registro";
  function redirectToLogin() {
    window.location.href = LOGIN_URL;
  }

  function redirectToRegistration() {
    window.location.href = REGISTRATION_URL;
  }

  function showWelcomeMessage() {
    Swal.fire({
      title: "¡Bienvenido!",
      icon: "info",
      html: `
      <div>
        <h4>Convierte en distribuidor<b> Sanhoo Textil</b></h4>
        <p>Aun no tienes cuenta de distribuidor da click en <b>Registro</b> o bien da click en <b>Iniciar sesión<b/></p>
      </div>
      `,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Iniciar sesion",
      denyButtonText: `Registrarme`,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        redirectToLogin();
      } else if (result.isDenied) {
        redirectToRegistration();
      }
    });
  }

  const token = localStorage.getItem("token");

  if (!token) {
    setTimeout(showWelcomeMessage, 2500);
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper
            sx={{
              backgroundColor: "#092635",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/img/logotipo.png")}
              height={70}
              alt="logo_distri"
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {products &&
          products.map((product) => (
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
              <ProductCartNoAuth product={product} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default Catalogo;
