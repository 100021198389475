import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import ModalImage from "react-modal-image-responsive";

const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
export default function ProductCartNoAuth({ product }) {
  const classes = useStyles();
  const { img } = product;
  const miImagen = require("../../assets/img/default.webp");

  return (
    // <Card
    //   sx={{
    //     boxShadow: 8,
    //     display: "flex",
    //     flexDirection: "column",
    //     height: { md: "auto", xs: "auto" },
    //   }}
    // >

    //   {img?.length > 0 ? (
    //     <Box sx={{ width: "100%", display: "flex" }}>
    //       <Grid container spacing={1}>
    //         <Grid
    //           item
    //           xs={12}
    //           sm={12}
    //           md={12}
    //           lg={12}
    //           xl={12}
    //           sx={{ display: "flex", justifyContent: "center" }}
    //         >
    //           <Box sx={{ width: "50%", height: "50%", position: "static" }}>
    //             <ModalImage
    //               className={classes.imgproduct}
    //               small={img[0]?.image}
    //               large={img[0]?.image}
    //               alt={product.name}
    //             />
    //           </Box>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   ) : (
    //     <Box sx={{ width: "100%", display: "flex" }}>
    //       <Grid container spacing={1}>
    //         <Grid
    //           item
    //           xs={12}
    //           sm={12}
    //           md={12}
    //           lg={12}
    //           xl={12}
    //           sx={{ display: "flex", justifyContent: "center" }}
    //         >
    //           <Box sx={{ width: "50%", height: "50%", position: "relative" }}>
    //             <ModalImage
    //               className={classes.imgproduct}
    //               small={miImagen}
    //               large={miImagen}
    //               alt={product.name}
    //             />
    //           </Box>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   )}
    //   <Box sx={{ display: "flex", justifyContent: "center" }}>
    //     <Typography
    //       fontWeight="bold"
    //       fontFamily="monospace"
    //       fontSize="20px"
    //       sx={{ color: "#092635", paddingLeft: 2, paddingRight: 2 }}
    //     >
    //       {product.name}
    //     </Typography>
    //   </Box>
    //   <Box
    //     sx={{ display: "flex", justifyContent: "space-between", padding: 3 }}
    //   >
    //     <>
    //       <Grid container spacing={2}>
    //         <Grid item xs={6}>
    //           <Grid
    //             sx={{
    //               display: "flex",
    //               justifyContent: "start",
    //               flexDirection: "column",
    //             }}
    //           >
    //             <>
    //               <Typography
    //                 fontWeight="bold"
    //                 fontFamily="monospace"
    //                 fontSize="30px"
    //                 sx={{
    //                   color: "#1B4242",
    //                   // textDecoration: "line-through",
    //                   // mt: -4,
    //                 }}
    //               >
    //                 $ {product.price_purchase}
    //               </Typography>
    //             </>
    //           </Grid>
    //         </Grid>
    //         <Grid item xs={6}>
    //           <Typography sx={{ fontSize: "20px", color: "#5C8374" }}>
    //             {product.stock} Disp.
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </>
    //   </Box>
    // </Card>
    <div className="plan">
      <div className="inner">
        <span class="pricing">
          <span>
            $ {product.price_purchase} <small>/ mxn</small>
          </span>
        </span>
        <p class="title">{product.name}</p>
        <div>
          {img?.length > 0 ? (
            <Box sx={{ width: "100%", display: "flex" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{ width: "100%", height: "50%", position: "static" }}
                  >
                    <ModalImage
                      className={classes.imgproduct}
                      small={img[0]?.image}
                      large={img[0]?.image}
                      alt={product.name}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box sx={{ width: "100%", display: "flex" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{ width: "100%", height: "50%", position: "relative" }}
                  >
                    <ModalImage
                      className={classes.imgproduct}
                      small={miImagen}
                      large={miImagen}
                      alt={product.name}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
}
