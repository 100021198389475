import React, { useContext, useEffect, useState } from "react";
// import Header from "../../components/Header/Header";
import { Grid, Typography } from "@mui/material";
import TableProductsCart from "../../components/Cart/TableProductsCart";
import CartContext from "../../Context/Cart/CartContext";
import Layout from "../../components/Layout/Layout";
const ProductsList = () => {
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  return (
    <Layout>
      {/* <Header /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
          <Typography fontWeight="bold" textAlign="center" variant="h5">
            Carrito de compras
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
          <TableProductsCart cart={cart} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ProductsList;
